<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12" style="z-index: 100;">
         <card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('elearning_tim.training_certificate')}}</h4>
          </template>
          <template v-slot:headerAction>
          <router-link to="certificate-list" :class="'mr-2 btn btn-success text-light'">{{ $t('elearning_tim.training_certificate') }} {{ $t('globalTrans.list') }}</router-link>
          <b-button type="button" variant="primary" class="mr-2" @click="printID">{{ $t('globalTrans.print') }}</b-button>
          </template>
        </card>
      </b-col>
      <b-col lg="12" sm="12"  id="printMe" style="height: 850px;">
        <div>
        <b-col lg="12" sm="12" class="thirdtrans">
          <div class="firsttrans">
          <div class="removeborder" style="border: 1px solid white;">
            <table class="container tabletwo secondtrans" style="background-size: 300px 300px;  background-repeat: no-repeat;background-position: center;">
              <tr style="text-align:center;">
                <th colspan="3" style="font-size: 16px;text-align:center;"><span class="certi">{{$t('elearning_tim.certificate_of_achievement_text')}}</span><br><span style="color: #823E97;">{{$t('elearning_tim.of_achievement')}}</span></th>
              </tr>
              <tr style="text-align:center;">
                <th colspan="3" class="thisis" style="text-align:center;">{{$t('elearning_tim.this_is_to_certify')}}</th>
              </tr>
            </table>
          </div>
          <img  :src="trainingElearningServiceBaseUrl+'storage/'+backlogo" class="img-fluid mb-2 marginforleft" alt="logo">
          </div>
          <div class="removeborder">
            <table class="container table" style="background-size: 300px 300px;  background-repeat: no-repeat;background-position: center;">
              <tr class="removeborder">
                <th class="removeborder" colspan="3" style="text-align:center;border: 1px solid white;">{{$t('elearning_tim.government_text')}}</th>
              </tr>
              <tr class="removeborder" style="text-align:center;">
                <th class="removeborder" colspan="3" style="font-size: 28px;padding: 0px;color: #006938;text-align:center;">{{$t('elearning_tim.national_agriculture_text')}}</th>
              </tr>
              <tr class="removeborder">
                <th class="removeborder" colspan="3" style="font-size: 14px;padding: 0px;text-align:center;font-weight: 900;">{{$t('elearning_tim.address_text')}}</th>
              </tr>
              <tr class="removeborder">
                <td class="removeborder" style="width: 54%;text-align: end;"><img  :src="trainingElearningServiceBaseUrl+'storage/'+logo" class="img-fluid mb-2" alt="logo" width="70"></td>
                <td class="removeborder" colspan="2" style="font-weight: bold;text-align:center;transform: translateX(76px);">{{$t('elearning_tim.sl_no_text')}} {{(certificate.sl_no)}}</td>
              </tr>
              <tr class="removeborder">
              </tr>
              <tr class="removeborder" style="text-align:center;margin-top:200px;">
              <div style="text-align:center;margin-top:183px;">
                <span style="text-align:center;margin-top:200px;"></span>
              </div>
              </tr>
              <tr class="removeborder" style="text-align:center;margin-top:200px;">
                <span style="text-align:center;margin-top:200px;"></span>
              </tr>
              <tr class="removeborder" style="text-align:center;">
                <th colspan="3" class="firstmove removeborder" style="font-size: 15px;font-weight: 100;text-align: left;padding: 45px;padding-bottom: 130px!important;border: 1px solid white;"><b style="font-weight: 600;font-size: 18px;text-transform: uppercase;">{{name}}</b>, {{designation}}, {{office}}, {{org}}, {{orgaddress}} {{$t('elearning_tim.main_one')}} <b style="font-weight: 600;font-style: italic;font-weight: 600;font-style: italic;font-size: 19px;"><span class="italicfont">{{trainingTitle}}</span></b> {{$t('elearning_tim.main_two')}} {{certificate.training_start_date | dateFormat}} {{$t('elearning_tim.to')}} {{certificate.training_end_date | dateFormat}} {{$t('elearning_tim.main_three')}}</th>
              </tr>
              <tr class="removeborder">
                <td class="w-50 removeborder" style="text-align:center!important;height:30px;width:200px"><span class="removeborder"><img  :src="trainingElearningServiceBaseUrl+'storage/uploads/certificate/' + certificateMain.course_coordinator_signature" class="img-fluid mb-2" alt="logo" width="70"></span></td>
                <td class="w-50 removeborder" style="text-align:center!important;height:30px;width:200px"><span class="removeborder"><img  :src="trainingElearningServiceBaseUrl+'storage/uploads/certificate/'+ certificateMain.director_general_signature" class="img-fluid mb-2" alt="logo" width="70"></span></td>
              </tr>
              <tr class="removeborder">
                <td class="w-50 removeborder" style="text-align:center!important;"><span class="removeborder" style="padding: 5px;border-top:1px solid black;">{{$t('elearning_tim.course_coordinator')}}</span></td>
                <td class="w-50 removeborder" style="text-align:center!important;"><span class="removeborder" style="padding: 5px;border-top:1px solid black;">{{$t('elearning_tim.director_general')}}</span></td>
              </tr>
            </table>
            </div>
        </b-col>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import VueHtmlToPaper from 'vue-html-to-paper'
import Vue from 'vue'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './export_pdf_details'
import { certificateDetails, reportHeadingListNew } from '../../api/routes'
const options = {
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=no'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    `${trainingElearningServiceBaseUrl}/print.css`
  ],
  autoClose: true
}
Vue.use(VueHtmlToPaper, options)
export default {
    components: {
    },
    created () {
      var recive = JSON.parse(localStorage.getItem('DataToGet'))
      // localStorage.setItem('objectToWorkWith', this.$route.query.item)
      // const newObj = JSON.parse(JSON.stringify(localStorage.getItem('objectToWorkWith')))
      this.getGenerateNo(this.$route.query.circular_memo_no)
      this.certificate = recive
      const obj = {
        org_id: this.certificate.org_id
      }
      RestApi.getData(trainingElearningServiceBaseUrl, reportHeadingListNew, obj).then(response => {
        if (response.success) {
          this.orglistReport = response.data
          const orgObj = this.orglistReport
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            if (this.$i18n.locale === 'bn') {
            this.org = orgObj.project_name_bn
            this.orgaddress = orgObj.address_bn
            this.logo = orgObj.right_logo
            this.backlogo = orgObj.background_logo
            } else {
            this.org = orgObj.project_name
            this.orgaddress = orgObj.address
            this.logo = orgObj.right_logo
            this.backlogo = orgObj.background_logo
            }
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          // this.paginationData(response.data)
        }
      })
      if (this.$i18n.locale === 'bn') {
       this.name = this.certificate.name_bn
      } else {
       this.name = this.certificate.name
      }
       this.designation = this.certificate.designation
      if (this.$i18n.locale === 'bn') {
       this.trainingTitle = this.certificate.training_title_bn
      } else {
       this.trainingTitle = this.certificate.training_title_en
      }
      const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === this.certificate.officeId)
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            if (this.$i18n.locale === 'bn') {
            this.office = officeObj.text_bn
            } else {
            this.office = officeObj.text_en
            }
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }
        const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === this.certificate.designation_id)
        if (this.$i18n.locale === 'bn') {
        this.designation = desigObj.text_bn
        } else {
        this.designation = desigObj.text_en
        }
        if (this.$route.query.circilarMemoNo) {
            this.getCircularMemoNo(this.$route.query.circilarMemoNo)
        }
        // this.getMaterils(this.honorariumSheet.training_title_id)
    },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      orglistReport: [],
      logo: '',
      backlogo: '',
      orgaddress: '',
      office: '',
      org: '',
      name: '',
      designation: '',
      trainingTitle: '',
      load: false,
      officeTypeList: [],
      honorariumSheet: [],
      materials: [],
      items: [],
      data: [],
      certificate: null,
      certificateMain: [],
      dataList: [],
      trainees: [],
      trainers: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      releaseDescription: [],
      releasedate: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getGenerateNo (circularMemoNo) {
      if (circularMemoNo) {
        const circularMemoNoData = {
          circular_memo_no: circularMemoNo
        }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'tim/certificate/show-details', circularMemoNoData)
        if (!result.success) {
          this.certificateMain = []
        } else {
            this.certificateMain = result.data
        }
        this.load = false
      }
    },
    async getCircularMemoNo (circularMemoNo) {
      if (circularMemoNo) {
          const circularMemoNo = {
            circular_memo_no: this.$route.query.circilarMemoNo
          }
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateDetails, circularMemoNo)
        if (!result.success) {
          this.honorariumSheet = []
        } else {
            this.honorariumSheet = result.data[0]
            this.releaseDescription = result.dataMain
            this.releasedate = result.datapublication
            this.getCustomDataZeroList(result.data[0])
            this.dataList = this.getCustomDataList(result.data)
            // const trainees = result.data.filter(trainee => trainee.payment_type === 'Trainee')
            // this.trainees = this.getCustomDataList(trainees)
            // this.materials = result.matherial
        }
        this.load = false
      }
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.released_order') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.honorariumSheet, this, this.dataList, this.releaseDescription, this.releasedate)
    },
    getCustomDataList (data) {
        const listData = data.map(item => {
          const personalObj = this.$store.state.TrainingElearning.commonObj.personalInfoList.find(doc => doc.value === parseInt(item.personal_info_id))
          const personalData = {}
          if (typeof personalObj !== 'undefined') {
            personalData.name = personalObj.text_en
            personalData.name_bn = personalObj.text_bn
          } else {
            personalData.name = ''
            personalData.name_bn = ''
          }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
          return Object.assign({}, item, personalData, orgData, officeData)
        })
        return listData
    },
    printID () {
            this.$htmlToPaper('printMe')
      // // Get HTML to print from element
      // const prtHtml = document.getElementById('print-area').innerHTML

      // // Open the print window
      // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      // WinPrint.document.write(`<!DOCTYPE html>
      // <html>
      //   <head>
      //    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" media="print">
      //    <style>
      //    @import url(//db.onlinewebfonts.com/c/46aebfd06fd610b5813d2d877dc23398?family=Tilda+Script);
      //    @import url(//db.onlinewebfonts.com/c/09400bc4b89c1605f1ccd16c0c305a8c?family=Lateef);
      //      @media print {
      //         .row::after {
      //             clear: both;
      //         }
      //         .row::before {
      //             display: table;
      //             content: " ";
      //         }
      //         .hidden_header {
      //           display: none
      //         }
      //         .card-border {
      //           border: 1px solid #b9bdc1;
      //           box-shadow: 1px 1px 6px -1px grey;
      //           background-color: #dee2e6;
      //         }
      //         [dir=ltr][mode=light] .card-body {
      //           flex: 1 1 auto;
      //           min-height: 1px;
      //           padding: 10px;
      //         }
      //         .report-name{
      //           font-weight: bold !important;
      //           text-transform: uppercase;
      //         }
      //         .my-btn{
      //           padding: 2px !important;
      //         }
      //         table {
      //         border-collapse: collapse;
      //         border: 1px solid black;
      //         background-color: white;
      //         width: 100%;
      //         }
      //         td, th {
      //         font-family: arial, sans-serif;
      //         text-align: left;
      //         padding: 8px;
      //         }
      //         .certi {
      //           font-family: 'Tilda Script'!important;
      //           font-size: 38px;
      //           font-weight: 100;
      //           color: #823E97;
      //         }
      //         .thisis {
      //           font-family: 'Lateef'!important;
      //           font-size: 32px;
      //           letter-spacing: 1px;
      //         }
      //      }
      //    </style>
      //   </head>
      //   <body>
      //     ${prtHtml}
      //   </body>
      // </html>`)

      // WinPrint.document.close()
      // WinPrint.focus()
      // WinPrint.print()
      // WinPrint.close()
    },
    getCustomDataZeroList (item) {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            this.honorariumSheet.org = orgObj.text_en
            this.honorariumSheet.org_bn = orgObj.text_bn
          } else {
            this.honorariumSheet.org = ''
            this.honorariumSheet.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            this.honorariumSheet.office = officeObj.text_en
            this.honorariumSheet.office_bn = officeObj.text_bn
          } else {
            this.honorariumSheet.office = ''
            this.honorariumSheet.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            this.honorariumSheet.fiscal_year = fiscalYearObj.text_en
            this.honorariumSheet.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            this.honorariumSheet.fiscal_year = ''
            this.honorariumSheet.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            this.honorariumSheet.training_title = trainingTitleObj.text_en
            this.honorariumSheet.training_title_bn = trainingTitleObj.text_bn
          } else {
            this.honorariumSheet.training_title = ''
            this.honorariumSheet.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            this.honorariumSheet.office_type = OfficeTypeListObj.text_en
            this.honorariumSheet.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            this.honorariumSheet.office_type = ''
            this.honorariumSheet.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            this.honorariumSheet.training_type = trainingTypeObj.text_en
            this.honorariumSheet.training_type_bn = trainingTypeObj.text_bn
          } else {
            this.honorariumSheet.training_type = ''
            this.honorariumSheet.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            this.honorariumSheet.training_category = trainingCategoryObj.text_en
            this.honorariumSheet.training_category_bn = trainingCategoryObj.text_bn
          } else {
            this.honorariumSheet.training_category = ''
            this.honorariumSheet.training_category_bn = ''
          }
      }
  }
}
</script>
<style>
@import url(//db.onlinewebfonts.com/c/46aebfd06fd610b5813d2d877dc23398?family=Tilda+Script);
@import url(//db.onlinewebfonts.com/c/09400bc4b89c1605f1ccd16c0c305a8c?family=Lateef);
@import url(//db.onlinewebfonts.com/c/6d36b202b654e70a446767b843911eea?family=Sexything);
  .hidden_header {
    display: none
  }
  .card-border {
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
  .table {
  border-collapse: collapse;
  background-color: white;
  width: 100%;
  }
  .tabletwo {
  border-collapse: collapse;
  width: 100%;
  }
  td, th {
  font-family: arial, sans-serif;
  text-align: left;
  padding: 8px;
  }
  .certi {
    font-family: 'Tilda Script'!important;
    font-size: 38px;
    font-weight: 100;
    color: #823E97;
  }
  .thisis {
    font-family: 'Lateef'!important;
    font-size: 32px;
    letter-spacing: 1px;
  }
  .italicfont {
    font-family: 'Sexy thing'!important;
  }
  .firsttrans {
    transform: translateY(454px);
  }
  .secondtrans {
  transform: translateY(155px);
  }
  .thirdtrans {
    transform: translateY(-436px);
  }
  .marginforleft {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .removeborder {
        border: 1px solid white;
  }
</style>
